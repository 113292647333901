/* .body {
  background-color: rgb(117, 145, 145);
}

.app {
  margin: 3rem auto;
  width: 30rem;
  padding: 1rem;
  background: rgb(120, 107, 238);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.app h1 {
  margin: 0;
  text-align: center;
} */

.wrapper {
  display: flex;
  align-items: center;
  padding: 2.4rem;
  /* padding: 0; */

  justify-content: center;

  background-image: url('./img/sunset-sail-1.jpg');

  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 100%;
}

.body {
  margin: 0;
}

.app {
  max-width: 132rem;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 1rem;
  /* overflow: hidden; */
}

.ui {
  display: grid;
  grid-template-columns: 1fr 8fr;

  justify-content: stretch;
  /* height: 100%; */
  background-color: #dee2e6;
}

.login {
  display: flex;
  margin: auto;
}

.display {
  background-color: #dee2e6;
  /* height: 100vh; */
  font-size: 1.4rem;
}

.entry {
  display: grid;
  grid-template-columns: 2fr 3fr;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 80em) {
  .wrapper {
    background-size: cover;
  }
  .app {
    max-width: 100rem;
    border-radius: none;
  }

  .ui {
    display: flex;
    flex-direction: column;
    /* display: grid;
    grid-template-columns: 0.5fr 6fr; */
  }
}

@media (max-width: 66em) {
  .app {
    /* height: 100vh; */

    overflow-y: scroll;
  }
}

@media (max-width: 57em) {
  .app {
    height: 96rem;
  }
  .ui {
    height: 100%;
  }
}

@media (max-height: 25em) {
  .ui {
    overflow-y: scroll;
  }
}

@media (max-width: 26em) {
  .wrapper {
    margin: 0;
    padding: 0;
  }
  .app {
    margin: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}

@media (max-height: 30em) {
  /* body {
    overflow-y: scroll;
  } */
  /* .wrapper {
    overflow-y: scroll;
  } */
  .app {
    margin: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}
