.btn,
.btn:link,
.btn:visited {
  background-color: #4dabf7;

  border: none;
  color: #e7f5ff;
  font-size: 1.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.087);
  width: 14rem;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
}

ion-icon {
  font-size: 24px;
  vertical-align: middle;
  padding-right: 0.4rem;
  color: #e7f5ff;
}
