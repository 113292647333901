.imports {
  display: flex;
  flex-direction: column;
  background-color: #dee2e6;
  padding: 1.6rem;
  width: 64rem;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
}

.confirm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.6rem;
}

.entries {
  /* display: flex;
    flex-direction: column; */

  height: 32rem;
  font-size: 1.4rem;

  /* padding: 1rem; */
  /* margin: 1rem 1rem 1rem 0; */
  background-color: #f8f9fa;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  width: 72rem;
}

.entry {
  display: grid;
  padding: 1rem;
}

.entryBox {
  margin-bottom: 1.6rem;
}

.btn,
.btn:link,
.btn:visited {
  background-color: #4dabf7;

  border: none;
  color: #f8f9fa;
  font-size: 1.8rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.087);
  width: 10rem;
  height: 3.2rem;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
  cursor: pointer;
}

ion-icon {
  font-size: 24px;
  vertical-align: middle;
  padding-right: 0.4rem;
}

.icon_btn {
  background-color: #dee2e6;
  border: none;
}

input {
  padding: 0.4rem;
}

.inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.inputRow {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
}

.inputbox {
  border: 1px solid #ced4da;
  width: 100%;
  margin-left: 0;
  height: 4rem;
}

.classbtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.link {
  color: #1864ab;
  cursor: pointer;
}

.results {
  /* border-top: 1px solid; */
  display: flex;
  flex-direction: column;

  padding: 1.2rem;
  margin: 1.2rem;
  background-color: #f8f9fa;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  max-height: 32rem;
  overflow-y: scroll;
  background-color: #e9ecef;
  height: 36rem;
  font-size: 1.6rem;
  /* width: 96rem; */
}

.result {
  padding: 1.2rem;
  border-bottom: 1px solid #dee2e6;
  font-size: 1.2rem;
}

th {
  text-align: left;
}

h3 {
  font-size: 2rem;
}

@media (max-width: 66em) {
  .results {
    display: flex;
    /* overflow-x: scroll; */
  }
  th {
    margin-right: 3.6rem;
    margin-bottom: 2.4rem;
  }
  /* td {
      margin-right: 2.4rem;
    } */
  /* td {
      border-bottom: 2px solid #495057;
    } */
}

@media (max-width: 32em) {
}
