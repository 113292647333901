.login {
  /* display: flex;
      flex-direction: column; */

  display: flex;

  height: 24rem;
  width: 48rem;
  font-size: 1.4rem;

  /* padding: 1rem; */

  background-color: #f8f9fa;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* padding: 4.8rem; */
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
}

.btn,
.btn:link,
.btn:visited {
  background-color: #4dabf7;
  /* margin-top: 2.4rem; */
  margin-bottom: 2.4rem;
  border: none;
  color: #e7f5ff;
  font-size: 1.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.087);
  width: 14rem;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
  cursor: pointer;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.4rem;
  width: 36rem;
}

.login_input {
  width: 36rem;
  border: 1px solid #ced4da;
  margin-bottom: 2.4rem;
}
