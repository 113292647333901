.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #212529;
  margin: 0;
  /* overflow: hidden; */
  padding: 1rem;
  /* line-height: 1.1; */
  text-align: center;
  padding-bottom: 2.4rem;
  border-bottom: 2px solid #495057;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 2.4rem;
  font-weight: bold;
  padding-bottom: 1.2rem;
}

.sub {
  padding-bottom: 1.2rem;
  color: #adb5bd;
  /* margin-top: 4.8rem;
  margin-bottom: 1.6rem; */
  margin-bottom: 2.4rem;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6.4rem;
  width: 6.4rem;
  padding: 0;
  margin-bottom: 2.4rem;
}

.btn,
.btn:link,
.btn:visited {
  background-color: #4dabf7;
  max-height: 3.6rem;
  border: none;
  color: #e7f5ff;
  font-size: 1.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.087);
  width: 14rem;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
  cursor: pointer;
}

@media (max-width: 80em) {
  .nav {
    flex-direction: row;
    max-width: 36rem;
    padding: 1.6rem;
    border-bottom: none;
    align-items: center;
    justify-content: space-evenly;
    border-right: 2px solid #495057;
  }
  .subnav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn {
    max-width: 12rem;
    max-height: 2.4rem;
  }

  .logo {
    margin: 0;
  }
  .sub {
    margin: 0;
  }
}

@media (max-width: 50em) {
  .nav {
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .btn {
    margin-bottom: 2.4rem;
  }
  .subnav {
    flex-direction: column;
    margin-top: 2.4rem;
    justify-content: space-between;
  }
}
