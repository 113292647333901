.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-height: 96rem; */
}

.header {
  font-size: 7.2rem;
  padding: 1.6rem;
  padding-bottom: 0;
}

.subheader {
  font-size: 1.6rem;
  padding: 1.6rem;
  padding-top: 0;
  font-style: italic;
  letter-spacing: 1.5px;
}

.caption {
  font-size: 2.4rem;
  /* font-style: italic; */
  letter-spacing: 1.2px;
  padding: 1.6rem;
  margin-top: 4.8rem;
}
