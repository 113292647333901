.newrace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem;
  margin: 1rem;

  background-color: #f8f9fa;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  height: 24rem;
  width: 48rem;
}

.btn,
.btn:link,
.btn:visited {
  background-color: #4dabf7;
  margin: 2.4rem;
  border: none;
  color: #e7f5ff;
  font-size: 1.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.087);
  width: 14rem;
}

.btn:hover,
.btn:active {
  background-color: #1864ab;
}

.inputbox {
  border: 1px solid #ced4da;
  margin-top: 0;
  width: 24rem;
}

.items {
  /* margin: 2.4rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  margin-top: 1.6rem;
}
